import LocalTrainingRepository from '@/shared/http/repositories/socialProject/localtraining'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import NewRegister from '@/components/layout/NewRegister/newRegister.vue'
import LocalTraining from '@/shared/models/localTraining'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'

export default {
  name: 'EditarLocalDeTreinamento',

  components: {
    [BreadCrumb.name]: BreadCrumb,
    [NewRegister.name]: NewRegister
  },

  data: () => ({
    placeholder: 'Nome',
    title: 'Local de Treinamento',
    localTraining: new LocalTraining(),
    isNew: false
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Locais de Treinamento',
            href: this.$router.resolve({ name: 'LocalTraining' }).href
          },
          {
            text: 'Editar local de treinamento',
            active: true
          }
        ]
      }
    }
  },

  created() {
    this.getById()
  },

  methods: {
    getById() {
      loading.push()
      LocalTrainingRepository.GetById(this.$route.params.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.localTraining = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar local de treinamento', 'ERRO')
        })
    },

    update(localTraining) {
      loading.push()
      LocalTrainingRepository.Update(localTraining)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          loading.pop()
          toast.success('Local de Treinamento editado com sucesso', 'EDITAR LOCAL DE TREINAMENTO')
          Promise.resolve()
          return this.$router.replace({ name: 'LocalTraining' })
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao editar local de treinamento', 'ERRO')
        })
    }
  }
}
